.App {
  text-align: center;
  background-color: #F5F5F5;
}

.App-header{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  padding: 10px;
}

.App-body {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  padding: 10px;
}

.App-button {
  border: none;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.button1 {border-radius: 40px; background-color:#005073; color: white;}
.button2 {border-radius: 40px; background-color:#107dac; color: white;}
.button3 {border-radius: 40px; background-color:#189ad3; color: white;}
.button4 {border-radius: 40px; background-color:#1ebbd7; color: black;}
.button5 {border-radius: 40px; background-color:#71c7ec; color: black;}
.button6 {border-radius: 40px; background-color:#ADD8E6; color: black;}
.buttonexternal {border-radius: 10px; background-color:#4C8BF5; color: white;}

h1 {
  border: none;
}

.tagShape {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #DCDCDC;
  color: #505050;
  border-radius: 20px;
  text-align: center;
}

.tagContainer{
  display: flex;
  align-items: center;
}